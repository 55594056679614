.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.shadow-sm {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 var(--epods--space-inset-s) var(--epods--space-inset-m) rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 var(--epods--space-inset-m) var(--epods--space-inset-xl) rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.border {
  border: 1px solid var(--epods--color-border) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid var(--epods--color-border) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right {
  border-right: 1px solid var(--epods--color-border) !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid var(--epods--color-border) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left {
  border-left: 1px solid var(--epods--color-border) !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: var(--epods--color-bg-primary) !important; }

.border-secondary {
  border-color: var(--epods--color-text-secondary) !important; }

.border-success {
  border-color: var(--epods--color-signal-success-border) !important; }

.border-info {
  border-color: var(--epods--color-signal-info-border) !important; }

.border-warning {
  border-color: var(--epods--color-signal-warning-border) !important; }

.border-danger {
  border-color: var(--epods--color-signal-error-border) !important; }

.border-ds-light {
  border-color: var(--epods--color-border-light) !important; }

.border-ds-dark {
  border-color: var(--epods--color-border) !important; }

.border-white {
  border-color: #fff !important; }

.border-dark {
  border-color: var(--epods--color-border) !important; }

.border-light {
  border-color: var(--epods--color-border-light) !important; }

.border-dashed {
  border-style: dashed !important; }

.w-1-24 {
  width: calc((100% / 24) * 1) !important; }

.w-2-24 {
  width: calc((100% / 24) * 2) !important; }

.w-3-24 {
  width: calc((100% / 24) * 3) !important; }

.w-4-24 {
  width: calc((100% / 24) * 4) !important; }

.w-5-24 {
  width: calc((100% / 24) * 5) !important; }

.w-6-24 {
  width: calc((100% / 24) * 6) !important; }

.w-7-24 {
  width: calc((100% / 24) * 7) !important; }

.w-8-24 {
  width: calc((100% / 24) * 8) !important; }

.w-9-24 {
  width: calc((100% / 24) * 9) !important; }

.w-10-24 {
  width: calc((100% / 24) * 10) !important; }

.w-11-24 {
  width: calc((100% / 24) * 11) !important; }

.w-12-24 {
  width: calc((100% / 24) * 12) !important; }

.w-13-24 {
  width: calc((100% / 24) * 13) !important; }

.w-14-24 {
  width: calc((100% / 24) * 14) !important; }

.w-15-24 {
  width: calc((100% / 24) * 15) !important; }

.w-16-24 {
  width: calc((100% / 24) * 16) !important; }

.w-17-24 {
  width: calc((100% / 24) * 17) !important; }

.w-18-24 {
  width: calc((100% / 24) * 18) !important; }

.w-19-24 {
  width: calc((100% / 24) * 19) !important; }

.w-20-24 {
  width: calc((100% / 24) * 20) !important; }

.w-21-24 {
  width: calc((100% / 24) * 21) !important; }

.w-22-24 {
  width: calc((100% / 24) * 22) !important; }

.w-23-24 {
  width: calc((100% / 24) * 23) !important; }

.w-24-24 {
  width: calc((100% / 24) * 24) !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-fitcontent {
  width: fit-content !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-1-24 {
  height: calc((100% / 24) * 1) !important; }

.h-2-24 {
  height: calc((100% / 24) * 2) !important; }

.h-3-24 {
  height: calc((100% / 24) * 3) !important; }

.h-4-24 {
  height: calc((100% / 24) * 4) !important; }

.h-5-24 {
  height: calc((100% / 24) * 5) !important; }

.h-6-24 {
  height: calc((100% / 24) * 6) !important; }

.h-7-24 {
  height: calc((100% / 24) * 7) !important; }

.h-8-24 {
  height: calc((100% / 24) * 8) !important; }

.h-9-24 {
  height: calc((100% / 24) * 9) !important; }

.h-10-24 {
  height: calc((100% / 24) * 10) !important; }

.h-11-24 {
  height: calc((100% / 24) * 11) !important; }

.h-12-24 {
  height: calc((100% / 24) * 12) !important; }

.h-13-24 {
  height: calc((100% / 24) * 13) !important; }

.h-14-24 {
  height: calc((100% / 24) * 14) !important; }

.h-15-24 {
  height: calc((100% / 24) * 15) !important; }

.h-16-24 {
  height: calc((100% / 24) * 16) !important; }

.h-17-24 {
  height: calc((100% / 24) * 17) !important; }

.h-18-24 {
  height: calc((100% / 24) * 18) !important; }

.h-19-24 {
  height: calc((100% / 24) * 19) !important; }

.h-20-24 {
  height: calc((100% / 24) * 20) !important; }

.h-21-24 {
  height: calc((100% / 24) * 21) !important; }

.h-22-24 {
  height: calc((100% / 24) * 22) !important; }

.h-23-24 {
  height: calc((100% / 24) * 23) !important; }

.h-24-24 {
  height: calc((100% / 24) * 24) !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-basis-1-24 {
  flex-basis: calc((100% / 24) * 1) !important; }

.flex-basis-2-24 {
  flex-basis: calc((100% / 24) * 2) !important; }

.flex-basis-3-24 {
  flex-basis: calc((100% / 24) * 3) !important; }

.flex-basis-4-24 {
  flex-basis: calc((100% / 24) * 4) !important; }

.flex-basis-5-24 {
  flex-basis: calc((100% / 24) * 5) !important; }

.flex-basis-6-24 {
  flex-basis: calc((100% / 24) * 6) !important; }

.flex-basis-7-24 {
  flex-basis: calc((100% / 24) * 7) !important; }

.flex-basis-8-24 {
  flex-basis: calc((100% / 24) * 8) !important; }

.flex-basis-9-24 {
  flex-basis: calc((100% / 24) * 9) !important; }

.flex-basis-10-24 {
  flex-basis: calc((100% / 24) * 10) !important; }

.flex-basis-11-24 {
  flex-basis: calc((100% / 24) * 11) !important; }

.flex-basis-12-24 {
  flex-basis: calc((100% / 24) * 12) !important; }

.flex-basis-13-24 {
  flex-basis: calc((100% / 24) * 13) !important; }

.flex-basis-14-24 {
  flex-basis: calc((100% / 24) * 14) !important; }

.flex-basis-15-24 {
  flex-basis: calc((100% / 24) * 15) !important; }

.flex-basis-16-24 {
  flex-basis: calc((100% / 24) * 16) !important; }

.flex-basis-17-24 {
  flex-basis: calc((100% / 24) * 17) !important; }

.flex-basis-18-24 {
  flex-basis: calc((100% / 24) * 18) !important; }

.flex-basis-19-24 {
  flex-basis: calc((100% / 24) * 19) !important; }

.flex-basis-20-24 {
  flex-basis: calc((100% / 24) * 20) !important; }

.flex-basis-21-24 {
  flex-basis: calc((100% / 24) * 21) !important; }

.flex-basis-22-24 {
  flex-basis: calc((100% / 24) * 22) !important; }

.flex-basis-23-24 {
  flex-basis: calc((100% / 24) * 23) !important; }

.flex-basis-24-24 {
  flex-basis: calc((100% / 24) * 24) !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-6 {
  order: 6 !important; }

.order-7 {
  order: 7 !important; }

.order-8 {
  order: 8 !important; }

.order-9 {
  order: 9 !important; }

.order-10 {
  order: 10 !important; }

.order-11 {
  order: 11 !important; }

.order-12 {
  order: 12 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-xs {
  margin: var(--epods--space-inset-xs) !important; }

.m-s {
  margin: var(--epods--space-inset-s) !important; }

.m-m {
  margin: var(--epods--space-inset-m) !important; }

.m-l {
  margin: var(--epods--space-inset-l) !important; }

.m-xl {
  margin: var(--epods--space-inset-xl) !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-xxs {
  margin-right: var(--epods--space-inset-xxs) !important;
  margin-left: var(--epods--space-inset-xxs) !important; }

.mx-xs {
  margin-right: var(--epods--space-inset-xs) !important;
  margin-left: var(--epods--space-inset-xs) !important; }

.mx-s {
  margin-right: var(--epods--space-inset-s) !important;
  margin-left: var(--epods--space-inset-s) !important; }

.mx-m {
  margin-right: var(--epods--space-inset-m) !important;
  margin-left: var(--epods--space-inset-m) !important; }

.mx-l {
  margin-right: var(--epods--space-inset-l) !important;
  margin-left: var(--epods--space-inset-l) !important; }

.mx-xl {
  margin-right: var(--epods--space-inset-xl) !important;
  margin-left: var(--epods--space-inset-xl) !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-xs {
  margin-top: var(--epods--space-inset-xs) !important;
  margin-bottom: var(--epods--space-inset-xs) !important; }

.my-s {
  margin-top: var(--epods--space-inset-s) !important;
  margin-bottom: var(--epods--space-inset-s) !important; }

.my-m {
  margin-top: var(--epods--space-inset-m) !important;
  margin-bottom: var(--epods--space-inset-m) !important; }

.my-l {
  margin-top: var(--epods--space-inset-l) !important;
  margin-bottom: var(--epods--space-inset-l) !important; }

.my-xl {
  margin-top: var(--epods--space-inset-xl) !important;
  margin-bottom: var(--epods--space-inset-xl) !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-xs {
  margin-top: var(--epods--space-inset-xs) !important; }

.mt-s {
  margin-top: var(--epods--space-inset-s) !important; }

.mt-m {
  margin-top: var(--epods--space-inset-m) !important; }

.mt-l {
  margin-top: var(--epods--space-inset-l) !important; }

.mt-xl {
  margin-top: var(--epods--space-inset-xl) !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-xs {
  margin-right: var(--epods--space-inset-xs) !important; }

.mr-s {
  margin-right: var(--epods--space-inset-s) !important; }

.mr-m {
  margin-right: var(--epods--space-inset-m) !important; }

.mr-l {
  margin-right: var(--epods--space-inset-l) !important; }

.mr-xl {
  margin-right: var(--epods--space-inset-xl) !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-xs {
  margin-bottom: var(--epods--space-inset-xs) !important; }

.mb-s {
  margin-bottom: var(--epods--space-inset-s) !important; }

.mb-m {
  margin-bottom: var(--epods--space-inset-m) !important; }

.mb-l {
  margin-bottom: var(--epods--space-inset-l) !important; }

.mb-xl {
  margin-bottom: var(--epods--space-inset-xl) !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-xxs {
  margin-left: var(--epods--space-inset-xxs) !important; }

.ml-xs {
  margin-left: var(--epods--space-inset-xs) !important; }

.ml-s {
  margin-left: var(--epods--space-inset-s) !important; }

.ml-m {
  margin-left: var(--epods--space-inset-m) !important; }

.ml-l {
  margin-left: var(--epods--space-inset-l) !important; }

.ml-xl {
  margin-left: var(--epods--space-inset-xl) !important; }

.ml-auto {
  margin-left: auto !important; }

.m-n-xs {
  margin: var(--epods--space-inset-negative-xs) !important; }

.m-n-s {
  margin: var(--epods--space-inset-negative-s) !important; }

.m-n-m {
  margin: var(--epods--space-inset-negative-m) !important; }

.m-n-l {
  margin: var(--epods--space-inset-negative-l) !important; }

.m-n-xl {
  margin: var(--epods--space-inset-negative-xl) !important; }

.mx-n-xs {
  margin-right: var(--epods--space-inset-negative-xs) !important;
  margin-left: var(--epods--space-inset-negative-xs) !important; }

.mx-n-s {
  margin-right: var(--epods--space-inset-negative-s) !important;
  margin-left: var(--epods--space-inset-negative-s) !important; }

.mx-n-m {
  margin-right: var(--epods--space-inset-negative-m) !important;
  margin-left: var(--epods--space-inset-negative-m) !important; }

.mx-n-l {
  margin-right: var(--epods--space-inset-negative-l) !important;
  margin-left: var(--epods--space-inset-negative-l) !important; }

.mx-n-xl {
  margin-right: var(--epods--space-inset-negative-xl) !important;
  margin-left: var(--epods--space-inset-negative-xl) !important; }

.my-n-xs {
  margin-top: var(--epods--space-inset-negative-xs) !important;
  margin-bottom: var(--epods--space-inset-negative-xs) !important; }

.my-n-s {
  margin-top: var(--epods--space-inset-negative-s) !important;
  margin-bottom: var(--epods--space-inset-negative-s) !important; }

.my-n-m {
  margin-top: var(--epods--space-inset-negative-m) !important;
  margin-bottom: var(--epods--space-inset-negative-m) !important; }

.my-n-l {
  margin-top: var(--epods--space-inset-negative-l) !important;
  margin-bottom: var(--epods--space-inset-negative-l) !important; }

.my-n-xl {
  margin-top: var(--epods--space-inset-negative-xl) !important;
  margin-bottom: var(--epods--space-inset-negative-xl) !important; }

.mt-n-xs {
  margin-top: var(--epods--space-inset-negative-xs) !important; }

.mt-n-s {
  margin-top: var(--epods--space-inset-negative-s) !important; }

.mt-n-m {
  margin-top: var(--epods--space-inset-negative-m) !important; }

.mt-n-l {
  margin-top: var(--epods--space-inset-negative-l) !important; }

.mt-n-xl {
  margin-top: var(--epods--space-inset-negative-xl) !important; }

.mr-n-xs {
  margin-right: var(--epods--space-inset-negative-xs) !important; }

.mr-n-s {
  margin-right: var(--epods--space-inset-negative-s) !important; }

.mr-n-m {
  margin-right: var(--epods--space-inset-negative-m) !important; }

.mr-n-l {
  margin-right: var(--epods--space-inset-negative-l) !important; }

.mr-n-xl {
  margin-right: var(--epods--space-inset-negative-xl) !important; }

.mb-n-xs {
  margin-bottom: var(--epods--space-inset-negative-xs) !important; }

.mb-n-s {
  margin-bottom: var(--epods--space-inset-negative-s) !important; }

.mb-n-m {
  margin-bottom: var(--epods--space-inset-negative-m) !important; }

.mb-n-l {
  margin-bottom: var(--epods--space-inset-negative-l) !important; }

.mb-n-xl {
  margin-bottom: var(--epods--space-inset-negative-xl) !important; }

.ml-n-xs {
  margin-left: var(--epods--space-inset-negative-xs) !important; }

.ml-n-s {
  margin-left: var(--epods--space-inset-negative-s) !important; }

.ml-n-m {
  margin-left: var(--epods--space-inset-negative-m) !important; }

.ml-n-l {
  margin-left: var(--epods--space-inset-negative-l) !important; }

.ml-n-xl {
  margin-left: var(--epods--space-inset-negative-xl) !important; }

.p-0 {
  padding: 0 !important; }

.p-xs {
  padding: var(--epods--space-inset-xs) !important; }

.p-s {
  padding: var(--epods--space-inset-s) !important; }

.p-m {
  padding: var(--epods--space-inset-m) !important; }

.p-l {
  padding: var(--epods--space-inset-l) !important; }

.p-xl {
  padding: var(--epods--space-inset-xl) !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-xs {
  padding-right: var(--epods--space-inset-xs) !important;
  padding-left: var(--epods--space-inset-xs) !important; }

.px-s {
  padding-right: var(--epods--space-inset-s) !important;
  padding-left: var(--epods--space-inset-s) !important; }

.px-m {
  padding-right: var(--epods--space-inset-m) !important;
  padding-left: var(--epods--space-inset-m) !important; }

.px-l {
  padding-right: var(--epods--space-inset-l) !important;
  padding-left: var(--epods--space-inset-l) !important; }

.px-xl {
  padding-right: var(--epods--space-inset-xl) !important;
  padding-left: var(--epods--space-inset-xl) !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-xs {
  padding-top: var(--epods--space-inset-xs) !important;
  padding-bottom: var(--epods--space-inset-xs) !important; }

.py-s {
  padding-top: var(--epods--space-inset-s) !important;
  padding-bottom: var(--epods--space-inset-s) !important; }

.py-m {
  padding-top: var(--epods--space-inset-m) !important;
  padding-bottom: var(--epods--space-inset-m) !important; }

.py-l {
  padding-top: var(--epods--space-inset-l) !important;
  padding-bottom: var(--epods--space-inset-l) !important; }

.py-xl {
  padding-top: var(--epods--space-inset-xl) !important;
  padding-bottom: var(--epods--space-inset-xl) !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-xs {
  padding-top: var(--epods--space-inset-xs) !important; }

.pt-s {
  padding-top: var(--epods--space-inset-s) !important; }

.pt-m {
  padding-top: var(--epods--space-inset-m) !important; }

.pt-l {
  padding-top: var(--epods--space-inset-l) !important; }

.pt-xl {
  padding-top: var(--epods--space-inset-xl) !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-xs {
  padding-right: var(--epods--space-inset-xs) !important; }

.pr-s {
  padding-right: var(--epods--space-inset-s) !important; }

.pr-m {
  padding-right: var(--epods--space-inset-m) !important; }

.pr-l {
  padding-right: var(--epods--space-inset-l) !important; }

.pr-xl {
  padding-right: var(--epods--space-inset-xl) !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-xs {
  padding-bottom: var(--epods--space-inset-xs) !important; }

.pb-s {
  padding-bottom: var(--epods--space-inset-s) !important; }

.pb-m {
  padding-bottom: var(--epods--space-inset-m) !important; }

.pb-l {
  padding-bottom: var(--epods--space-inset-l) !important; }

.pb-xl {
  padding-bottom: var(--epods--space-inset-xl) !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-xs {
  padding-left: var(--epods--space-inset-xs) !important; }

.pl-s {
  padding-left: var(--epods--space-inset-s) !important; }

.pl-m {
  padding-left: var(--epods--space-inset-m) !important; }

.pl-l {
  padding-left: var(--epods--space-inset-l) !important; }

.pl-xl {
  padding-left: var(--epods--space-inset-xl) !important; }

.text-regular {
  font-family: var(--epods--font-base); }

.text-semibold {
  font-family: var(--epods--font-base-semibold); }

.text-bold {
  font-family: var(--epods--font-base-semibold); }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-overflow-ellipsis {
  text-overflow: ellipsis; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

.text-primary-action {
  color: var(--epods--color-text-primary-action) !important; }

.text-secondary {
  color: var(--epods--color-text-secondary) !important; }

.text-success {
  color: var(--epods--color-signal-success-text) !important; }

.text-info {
  color: var(--epods--color-signal-info-text) !important; }

.text-warning {
  color: var(--epods--color-signal-warning-text) !important; }

.text-danger {
  color: var(--epods--color-signal-error-text) !important; }

.text-ds {
  color: var(--epods--color-text-ds-blue) !important; }

.text-ds-dark {
  color: var(--epods--color-text-ds-dark) !important; }

.text-primary {
  color: var(--epods--color-text-primary) !important; }

.text-inverted {
  color: var(--epods--color-text-inverted) !important; }

.text-muted {
  color: var(--epods--color-text-disabled) !important; }

.text-reset {
  color: inherit !important; }

.icon-x-small {
  font-size: var(--epods--icon-x-small) !important; }

.icon-small {
  font-size: var(--epods--icon-small) !important; }

.icon-medium {
  font-size: var(--epods--icon-medium) !important; }

.icon-large {
  font-size: var(--epods--icon-large) !important; }

.icon-x-large {
  font-size: var(--epods--icon-x-large) !important; }

.space-inset-xxxs {
  padding: var(--epods--space-inset-xxxs); }

.space-inset-xxs {
  padding: var(--epods--space-inset-xxs); }

.space-inset-xs {
  padding: var(--epods--space-inset-xs); }

.space-inset-s {
  padding: var(--epods--space-inset-s); }

.space-inset-s2 {
  padding: var(--epods--space-inset-s2); }

.space-inset-m {
  padding: var(--epods--space-inset-m); }

.space-inset-l {
  padding: var(--epods--space-inset-l); }

.space-inset-xl {
  padding: var(--epods--space-inset-xl); }

.space-inset-negative-xxxs {
  margin: var(--epods--space-inset-negative-xxxs); }

.space-inset-negative-xxs {
  margin: var(--epods--space-inset-negative-xxs); }

.space-inset-negative-xs {
  margin: var(--epods--space-inset-negative-xs); }

.space-inset-negative-s {
  margin: var(--epods--space-inset-negative-s); }

.space-inset-negative-s2 {
  margin: var(--epods--space-inset-negative-s2); }

.space-inset-negative-m {
  margin: var(--epods--space-inset-negative-m); }

.space-inset-negative-l {
  margin: var(--epods--space-inset-negative-l); }

.space-inset-negative-xl {
  margin: var(--epods--space-inset-negative-xl); }

.space-stack-xs {
  margin: var(--epods--space-stack-xs); }

.space-stack-s {
  margin: var(--epods--space-stack-s); }

.space-stack-m {
  margin: var(--epods--space-stack-m); }

.space-stack-l {
  margin: var(--epods--space-stack-l); }

.space-stack-xl {
  margin: var(--epods--space-stack-xl); }

.space-inline-xs {
  margin: var(--epods--space-inline-xs); }

.space-inline-s {
  margin: var(--epods--space-inline-s); }

.space-inline-m {
  margin: var(--epods--space-inline-m); }

.space-inline-l {
  margin: var(--epods--space-inline-l); }

.space-inline-xl {
  margin: var(--epods--space-inline-xl); }

.space-inset-squish-s {
  padding: var(--epods--space-inset-squish-s); }

.space-inset-squish-m {
  padding: var(--epods--space-inset-squish-m); }

.space-inset-squish-l {
  padding: var(--epods--space-inset-squish-l); }

.space-inset-strech-s {
  padding: var(--epods--space-inset-strech-s); }

.space-inset-strech-m {
  padding: var(--epods--space-inset-strech-m); }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: var(--epods--color-bg-primary) !important; }

.bg-secondary {
  background-color: var(--epods--color-bg-secondary) !important; }

.bg-success {
  background-color: var(--epods--color-signal-success-bg) !important; }

.bg-info {
  background-color: var(--epods--color-signal-info-bg) !important; }

.bg-warning {
  background-color: var(--epods--color-signal-warning-bg) !important; }

.bg-danger {
  background-color: var(--epods--color-signal-error-bg) !important; }

.bg-body,
.bg {
  background-color: var(--epods--color-bg) !important; }

.bg-light {
  background-color: var(--epods--color-bg-light) !important; }

.bg-canvas {
  background-color: var(--epods--color-bg-canvas) !important; }

.bg-inactive {
  background-color: var(--epods--color-bg-inactive) !important; }

.bg-heading {
  background-color: var(--epods--color-bg-heading) !important; }

.bg-overlays {
  background-color: var(--epods--color-bg-overlays) !important; }

.bg-visited {
  background-color: var(--epods--color-bg-visited) !important; }

.bg-hover {
  background-color: var(--epods--color-bg-hover) !important; }

.bg-active {
  background-color: var(--epods--color-bg-active) !important; }

.bg-ds {
  background-color: var(--epods--color-text-ds-blue) !important; }

.bg-ds-dark {
  background-color: var(--epods--color-text-ds-dark) !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.font-italic {
  font-style: italic !important; }

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important; }

.rounded {
  border-radius: var(--epods--border-radius) !important; }

.rounded-sm {
  border-radius: var(--epods--border-radius-sm) !important; }

.rounded-lg {
  border-radius: var(--epods--border-radius-lg) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: var(--epods--border-radius) !important;
  border-top-right-radius: var(--epods--border-radius) !important; }

.rounded-right {
  border-top-right-radius: var(--epods--border-radius) !important;
  border-bottom-right-radius: var(--epods--border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--epods--border-radius) !important;
  border-bottom-left-radius: var(--epods--border-radius) !important; }

.rounded-left {
  border-bottom-left-radius: var(--epods--border-radius) !important;
  border-top-left-radius: var(--epods--border-radius) !important; }

.rounded-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }

@media print {
  .print\:hidden {
    display: none; } }

.scrollable-y {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important; }

@media screen and (min-width: 414px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-6 {
    order: 6 !important; }
  .order-sm-7 {
    order: 7 !important; }
  .order-sm-8 {
    order: 8 !important; }
  .order-sm-9 {
    order: 9 !important; }
  .order-sm-10 {
    order: 10 !important; }
  .order-sm-11 {
    order: 11 !important; }
  .order-sm-12 {
    order: 12 !important; }
  .order-sm-last {
    order: 13 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-sm-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-sm-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-sm-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-sm-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-sm-s {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-sm-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-sm-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-sm-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-sm-s {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-sm-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-sm-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-sm-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-sm-s {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-sm-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-sm-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-sm-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-sm-s {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-sm-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-sm-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-sm-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-sm-s {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-sm-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-sm-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-sm-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-sm-s {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-sm-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-sm-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-sm-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .m-sm-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-sm-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-sm-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-sm-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-sm-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-sm-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-sm-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-sm-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-sm-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-sm-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-sm-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-sm-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-sm-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-sm-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-sm-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-sm-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-sm-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-sm-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-sm-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-sm-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-sm-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-sm-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-sm-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-sm-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-sm-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-sm-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-sm-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-sm-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-sm-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-sm-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-sm-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-sm-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-sm-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-sm-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-sm-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-xs {
    padding: var(--epods--space-inset-xs) !important; }
  .p-sm-s {
    padding: var(--epods--space-inset-s) !important; }
  .p-sm-m {
    padding: var(--epods--space-inset-m) !important; }
  .p-sm-l {
    padding: var(--epods--space-inset-l) !important; }
  .p-sm-xl {
    padding: var(--epods--space-inset-xl) !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-sm-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-sm-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-sm-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-sm-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-sm-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-sm-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-sm-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-sm-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-sm-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-sm-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-sm-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-sm-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-sm-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-sm-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-sm-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-sm-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-sm-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-sm-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-sm-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-sm-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-sm-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-sm-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-sm-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-sm-xl {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .text-sm-justify {
    text-align: justify !important; }
  .w-sm-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-sm-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-sm-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-sm-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-sm-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-sm-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-sm-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-sm-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-sm-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-sm-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-sm-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-sm-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-sm-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-sm-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-sm-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-sm-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-sm-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-sm-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-sm-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-sm-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-sm-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-sm-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-sm-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-sm-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .mw-sm-100 {
    max-width: 100% !important; }
  .vw-sm-100 {
    width: 100vw !important; }
  .min-sm-vw-100 {
    min-width: 100vw !important; }
  .h-sm-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-sm-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-sm-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-sm-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-sm-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-sm-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-sm-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-sm-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-sm-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-sm-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-sm-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-sm-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-sm-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-sm-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-sm-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-sm-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-sm-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-sm-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-sm-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-sm-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-sm-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-sm-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-sm-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-sm-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .mh-sm-100 {
    max-height: 100% !important; }
  .vh-sm-100 {
    height: 100vh !important; }
  .min-sm-vh-100 {
    min-height: 100vh !important; } }

@media screen and (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-6 {
    order: 6 !important; }
  .order-md-7 {
    order: 7 !important; }
  .order-md-8 {
    order: 8 !important; }
  .order-md-9 {
    order: 9 !important; }
  .order-md-10 {
    order: 10 !important; }
  .order-md-11 {
    order: 11 !important; }
  .order-md-12 {
    order: 12 !important; }
  .order-md-last {
    order: 13 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-md-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-md-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-md-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-md-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-md-s {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-md-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-md-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-md-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-md-s {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-md-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-md-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-md-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-md-s {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-md-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-md-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-md-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-md-s {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-md-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-md-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-md-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-md-s {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-md-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-md-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-md-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-md-s {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-md-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-md-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-md-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .m-md-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-md-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-md-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-md-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-md-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-md-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-md-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-md-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-md-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-md-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-md-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-md-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-md-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-md-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-md-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-md-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-md-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-md-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-md-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-md-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-md-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-md-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-md-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-md-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-md-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-md-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-md-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-md-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-md-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-md-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-md-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-md-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-md-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-md-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-md-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-xs {
    padding: var(--epods--space-inset-xs) !important; }
  .p-md-s {
    padding: var(--epods--space-inset-s) !important; }
  .p-md-m {
    padding: var(--epods--space-inset-m) !important; }
  .p-md-l {
    padding: var(--epods--space-inset-l) !important; }
  .p-md-xl {
    padding: var(--epods--space-inset-xl) !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-md-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-md-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-md-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-md-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-md-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-md-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-md-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-md-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-md-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-md-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-md-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-md-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-md-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-md-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-md-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-md-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-md-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-md-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-md-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-md-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-md-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-md-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-md-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-md-xl {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .text-md-justify {
    text-align: justify !important; }
  .w-md-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-md-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-md-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-md-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-md-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-md-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-md-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-md-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-md-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-md-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-md-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-md-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-md-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-md-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-md-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-md-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-md-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-md-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-md-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-md-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-md-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-md-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-md-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-md-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .mw-md-100 {
    max-width: 100% !important; }
  .vw-md-100 {
    width: 100vw !important; }
  .min-md-vw-100 {
    min-width: 100vw !important; }
  .h-md-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-md-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-md-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-md-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-md-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-md-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-md-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-md-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-md-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-md-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-md-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-md-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-md-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-md-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-md-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-md-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-md-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-md-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-md-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-md-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-md-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-md-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-md-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-md-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .mh-md-100 {
    max-height: 100% !important; }
  .vh-md-100 {
    height: 100vh !important; }
  .min-md-vh-100 {
    min-height: 100vh !important; } }

@media screen and (min-width: 960px) and (orientation: landscape) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-6 {
    order: 6 !important; }
  .order-lg-7 {
    order: 7 !important; }
  .order-lg-8 {
    order: 8 !important; }
  .order-lg-9 {
    order: 9 !important; }
  .order-lg-10 {
    order: 10 !important; }
  .order-lg-11 {
    order: 11 !important; }
  .order-lg-12 {
    order: 12 !important; }
  .order-lg-last {
    order: 13 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-lg-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-lg-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-lg-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-lg-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-lg-s {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-lg-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-lg-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-lg-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-lg-s {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-lg-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-lg-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-lg-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-lg-s {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-lg-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-lg-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-lg-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-lg-s {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-lg-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-lg-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-lg-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-lg-s {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-lg-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-lg-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-lg-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-lg-s {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-lg-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-lg-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-lg-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .m-lg-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-lg-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-lg-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-lg-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-lg-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-lg-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-lg-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-lg-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-lg-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-lg-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-lg-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-lg-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-lg-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-lg-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-lg-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-lg-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-lg-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-lg-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-lg-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-lg-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-lg-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-lg-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-lg-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-lg-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-lg-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-lg-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-lg-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-lg-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-lg-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-lg-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-lg-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-lg-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-lg-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-lg-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-lg-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: var(--epods--space-inset-xs) !important; }
  .p-lg-2 {
    padding: var(--epods--space-inset-s) !important; }
  .p-lg-3 {
    padding: var(--epods--space-inset-m) !important; }
  .p-lg-4 {
    padding: var(--epods--space-inset-l) !important; }
  .p-lg-5 {
    padding: var(--epods--space-inset-xl) !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-lg-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-lg-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-lg-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-lg-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-lg-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-lg-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-lg-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-lg-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-lg-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-lg-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-lg-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-lg-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-lg-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-lg-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-lg-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-lg-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-lg-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-lg-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-lg-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-lg-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-lg-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-lg-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-lg-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-lg-xl {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .text-lg-justify {
    text-align: justify !important; }
  .w-lg-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-lg-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-lg-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-lg-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-lg-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-lg-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-lg-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-lg-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-lg-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-lg-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-lg-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-lg-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-lg-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-lg-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-lg-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-lg-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-lg-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-lg-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-lg-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-lg-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-lg-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-lg-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-lg-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-lg-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .mw-lg-100 {
    max-width: 100% !important; }
  .vw-lg-100 {
    width: 100vw !important; }
  .min-lg-vw-100 {
    min-width: 100vw !important; }
  .h-lg-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-lg-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-lg-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-lg-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-lg-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-lg-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-lg-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-lg-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-lg-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-lg-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-lg-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-lg-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-lg-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-lg-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-lg-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-lg-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-lg-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-lg-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-lg-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-lg-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-lg-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-lg-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-lg-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-lg-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .mh-lg-100 {
    max-height: 100% !important; }
  .vh-lg-100 {
    height: 100vh !important; }
  .min-lg-vh-100 {
    min-height: 100vh !important; } }

@media screen and (min-width: 1280px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-6 {
    order: 6 !important; }
  .order-xl-7 {
    order: 7 !important; }
  .order-xl-8 {
    order: 8 !important; }
  .order-xl-9 {
    order: 9 !important; }
  .order-xl-10 {
    order: 10 !important; }
  .order-xl-11 {
    order: 11 !important; }
  .order-xl-12 {
    order: 12 !important; }
  .order-xl-last {
    order: 13 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-xl-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-xl-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-xl-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-xl-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-xl-s {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-xl-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-xl-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-xl-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-xl-s {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-xl-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-xl-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-xl-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-xl-s {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-xl-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-xl-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-xl-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-xl-s {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-xl-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-xl-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-xl-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-xl-s {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-xl-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-xl-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-xl-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-xl-s {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-xl-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-xl-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-xl-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .m-xl-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-xl-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-xl-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-xl-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-xl-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-xl-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-xl-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-xl-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-xl-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-xl-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-xl-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-xl-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-xl-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-xl-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-xl-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-xl-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-xl-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-xl-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-xl-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-xl-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-xl-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-xl-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-xl-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-xl-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-xl-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-xl-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-xl-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-xl-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-xl-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-xl-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-xl-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-xl-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-xl-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-xl-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-xl-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-xs {
    padding: var(--epods--space-inset-xs) !important; }
  .p-xl-s {
    padding: var(--epods--space-inset-s) !important; }
  .p-xl-m {
    padding: var(--epods--space-inset-m) !important; }
  .p-xl-l {
    padding: var(--epods--space-inset-l) !important; }
  .p-xl-xl {
    padding: var(--epods--space-inset-xl) !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-xl-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-xl-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-xl-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-xl-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-xl-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-xl-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-xl-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-xl-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-xl-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-xl-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-xl-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-xl-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-xl-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-xl-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-xl-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-xl-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-xl-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-xl-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-xl-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-xl-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-xl-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-xl-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-xl-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-xl-xl {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .text-xl-justify {
    text-align: justify !important; }
  .w-xl-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-xl-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-xl-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-xl-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-xl-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-xl-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-xl-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-xl-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-xl-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-xl-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-xl-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-xl-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-xl-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-xl-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-xl-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-xl-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-xl-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-xl-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-xl-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-xl-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-xl-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-xl-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-xl-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-xl-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .mw-xl-100 {
    max-width: 100% !important; }
  .vw-xl-100 {
    width: 100vw !important; }
  .min-xl-vw-100 {
    min-width: 100vw !important; }
  .h-xl-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-xl-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-xl-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-xl-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-xl-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-xl-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-xl-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-xl-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-xl-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-xl-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-xl-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-xl-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-xl-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-xl-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-xl-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-xl-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-xl-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-xl-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-xl-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-xl-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-xl-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-xl-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-xl-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-xl-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .mh-xl-100 {
    max-height: 100% !important; }
  .vh-xl-100 {
    height: 100vh !important; }
  .min-xl-vh-100 {
    min-height: 100vh !important; } }

@media screen and (min-width: 1600px) {
  .float-xl2-left {
    float: left !important; }
  .float-xl2-right {
    float: right !important; }
  .float-xl2-none {
    float: none !important; }
  .d-xl2-none {
    display: none !important; }
  .d-xl2-inline {
    display: inline !important; }
  .d-xl2-inline-block {
    display: inline-block !important; }
  .d-xl2-block {
    display: block !important; }
  .d-xl2-table {
    display: table !important; }
  .d-xl2-table-row {
    display: table-row !important; }
  .d-xl2-table-cell {
    display: table-cell !important; }
  .d-xl2-flex {
    display: flex !important; }
  .d-xl2-inline-flex {
    display: inline-flex !important; }
  .flex-xl2-fill {
    flex: 1 1 auto !important; }
  .flex-xl2-row {
    flex-direction: row !important; }
  .flex-xl2-column {
    flex-direction: column !important; }
  .flex-xl2-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl2-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl2-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl2-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl2-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl2-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl2-wrap {
    flex-wrap: wrap !important; }
  .flex-xl2-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl2-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl2-start {
    justify-content: flex-start !important; }
  .justify-content-xl2-end {
    justify-content: flex-end !important; }
  .justify-content-xl2-center {
    justify-content: center !important; }
  .justify-content-xl2-between {
    justify-content: space-between !important; }
  .justify-content-xl2-around {
    justify-content: space-around !important; }
  .align-items-xl2-start {
    align-items: flex-start !important; }
  .align-items-xl2-end {
    align-items: flex-end !important; }
  .align-items-xl2-center {
    align-items: center !important; }
  .align-items-xl2-baseline {
    align-items: baseline !important; }
  .align-items-xl2-stretch {
    align-items: stretch !important; }
  .align-content-xl2-start {
    align-content: flex-start !important; }
  .align-content-xl2-end {
    align-content: flex-end !important; }
  .align-content-xl2-center {
    align-content: center !important; }
  .align-content-xl2-between {
    align-content: space-between !important; }
  .align-content-xl2-around {
    align-content: space-around !important; }
  .align-content-xl2-stretch {
    align-content: stretch !important; }
  .align-self-xl2-auto {
    align-self: auto !important; }
  .align-self-xl2-start {
    align-self: flex-start !important; }
  .align-self-xl2-end {
    align-self: flex-end !important; }
  .align-self-xl2-center {
    align-self: center !important; }
  .align-self-xl2-baseline {
    align-self: baseline !important; }
  .align-self-xl2-stretch {
    align-self: stretch !important; }
  .order-xl2-first {
    order: -1 !important; }
  .order-xl2-0 {
    order: 0 !important; }
  .order-xl2-1 {
    order: 1 !important; }
  .order-xl2-2 {
    order: 2 !important; }
  .order-xl2-3 {
    order: 3 !important; }
  .order-xl2-4 {
    order: 4 !important; }
  .order-xl2-5 {
    order: 5 !important; }
  .order-xl2-6 {
    order: 6 !important; }
  .order-xl2-7 {
    order: 7 !important; }
  .order-xl2-8 {
    order: 8 !important; }
  .order-xl2-9 {
    order: 9 !important; }
  .order-xl2-10 {
    order: 10 !important; }
  .order-xl2-11 {
    order: 11 !important; }
  .order-xl2-12 {
    order: 12 !important; }
  .order-xl2-last {
    order: 13 !important; }
  .m-xl2-0 {
    margin: 0 !important; }
  .m-xl2-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-xl2-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-xl2-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-xl2-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-xl2-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-xl2-auto {
    margin: auto !important; }
  .mx-xl2-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl2-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-xl2-s {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-xl2-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-xl2-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-xl2-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-xl2-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl2-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl2-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-xl2-s {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-xl2-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-xl2-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-xl2-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-xl2-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl2-0 {
    margin-top: 0 !important; }
  .mt-xl2-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-xl2-s {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-xl2-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-xl2-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-xl2-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-xl2-auto {
    margin-top: auto !important; }
  .mr-xl2-0 {
    margin-right: 0 !important; }
  .mr-xl2-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-xl2-s {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-xl2-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-xl2-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-xl2-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-xl2-auto {
    margin-right: auto !important; }
  .mb-xl2-0 {
    margin-bottom: 0 !important; }
  .mb-xl2-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-xl2-s {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-xl2-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-xl2-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-xl2-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-xl2-auto {
    margin-bottom: auto !important; }
  .ml-xl2-0 {
    margin-left: 0 !important; }
  .ml-xl2-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-xl2-s {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-xl2-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-xl2-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-xl2-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-xl2-auto {
    margin-left: auto !important; }
  .m-xl2-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-xl2-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-xl2-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-xl2-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-xl2-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-xl2-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-xl2-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-xl2-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-xl2-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-xl2-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-xl2-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-xl2-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-xl2-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-xl2-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-xl2-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-xl2-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-xl2-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-xl2-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-xl2-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-xl2-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-xl2-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-xl2-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-xl2-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-xl2-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-xl2-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-xl2-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-xl2-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-xl2-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-xl2-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-xl2-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-xl2-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-xl2-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-xl2-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-xl2-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-xl2-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-xl2-0 {
    padding: 0 !important; }
  .p-xl2-xs {
    padding: var(--epods--space-inset-xs) !important; }
  .p-xl2-s {
    padding: var(--epods--space-inset-s) !important; }
  .p-xl2-m {
    padding: var(--epods--space-inset-m) !important; }
  .p-xl2-l {
    padding: var(--epods--space-inset-l) !important; }
  .p-xl2-xl {
    padding: var(--epods--space-inset-xl) !important; }
  .px-xl2-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl2-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-xl2-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-xl2-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-xl2-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-xl2-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-xl2-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl2-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-xl2-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-xl2-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-xl2-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-xl2-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-xl2-0 {
    padding-top: 0 !important; }
  .pt-xl2-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-xl2-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-xl2-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-xl2-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-xl2-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-xl2-0 {
    padding-right: 0 !important; }
  .pr-xl2-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-xl2-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-xl2-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-xl2-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-xl2-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-xl2-0 {
    padding-bottom: 0 !important; }
  .pb-xl2-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-xl2-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-xl2-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-xl2-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-xl2-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-xl2-0 {
    padding-left: 0 !important; }
  .pl-xl2-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-xl2-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-xl2-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-xl2-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-xl2-xs {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-xl2-left {
    text-align: left !important; }
  .text-xl2-right {
    text-align: right !important; }
  .text-xl2-center {
    text-align: center !important; }
  .text-xl2-justify {
    text-align: justify !important; }
  .w-xl2-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-xl2-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-xl2-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-xl2-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-xl2-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-xl2-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-xl2-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-xl2-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-xl2-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-xl2-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-xl2-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-xl2-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-xl2-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-xl2-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-xl2-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-xl2-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-xl2-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-xl2-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-xl2-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-xl2-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-xl2-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-xl2-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-xl2-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-xl2-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-xl2-25 {
    width: 25% !important; }
  .w-xl2-50 {
    width: 50% !important; }
  .w-xl2-75 {
    width: 75% !important; }
  .w-xl2-100 {
    width: 100% !important; }
  .w-xl2-auto {
    width: auto !important; }
  .mw-xl2-100 {
    max-width: 100% !important; }
  .vw-xl2-100 {
    width: 100vw !important; }
  .min-xl2-vw-100 {
    min-width: 100vw !important; }
  .h-xl2-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-xl2-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-xl2-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-xl2-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-xl2-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-xl2-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-xl2-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-xl2-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-xl2-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-xl2-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-xl2-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-xl2-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-xl2-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-xl2-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-xl2-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-xl2-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-xl2-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-xl2-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-xl2-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-xl2-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-xl2-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-xl2-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-xl2-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-xl2-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-xl2-25 {
    height: 25% !important; }
  .h-xl2-50 {
    height: 50% !important; }
  .h-xl2-75 {
    height: 75% !important; }
  .h-xl2-100 {
    height: 100% !important; }
  .h-xl2-auto {
    height: auto !important; }
  .mh-xl2-100 {
    max-height: 100% !important; }
  .vh-xl2-100 {
    height: 100vh !important; }
  .min-xl2-vh-100 {
    min-height: 100vh !important; } }

@media screen and (min-width: 1921px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-6 {
    order: 6 !important; }
  .order-xxl-7 {
    order: 7 !important; }
  .order-xxl-8 {
    order: 8 !important; }
  .order-xxl-9 {
    order: 9 !important; }
  .order-xxl-10 {
    order: 10 !important; }
  .order-xxl-11 {
    order: 11 !important; }
  .order-xxl-12 {
    order: 12 !important; }
  .order-xxl-last {
    order: 13 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-xxl-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-xxl-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-xxl-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-xxl-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-xxl-s {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-xxl-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-xxl-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-xxl-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-xxl-s {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-xxl-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-xxl-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-xxl-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-xxl-s {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-xxl-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-xxl-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-xxl-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-0 {
    margin-right: 0 !important; }
  .mr-xxl-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-xxl-s {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-xxl-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-xxl-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-xxl-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-xxl-s {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-xxl-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-xxl-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-xxl-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-0 {
    margin-left: 0 !important; }
  .ml-xxl-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-xxl-s {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-xxl-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-xxl-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-xxl-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-xxl-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-xxl-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-xxl-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-xxl-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-xxl-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-xxl-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-xxl-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-xxl-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-xxl-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-xxl-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-xxl-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-xxl-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-xxl-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-xxl-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-xxl-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-xxl-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-xxl-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-xxl-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-xxl-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-xxl-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-xxl-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-xxl-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-xxl-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-xxl-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-xxl-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-xxl-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-xxl-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-xxl-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-xxl-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-xxl-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-xxl-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-xxl-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-xxl-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-xxl-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-xs {
    padding: var(--epods--space-inset-xs) !important; }
  .p-xxl-s {
    padding: var(--epods--space-inset-s) !important; }
  .p-xxl-m {
    padding: var(--epods--space-inset-m) !important; }
  .p-xxl-l {
    padding: var(--epods--space-inset-l) !important; }
  .p-xxl-xl {
    padding: var(--epods--space-inset-xl) !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-xxl-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-xxl-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-xxl-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-xxl-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-xxl-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-xxl-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-xxl-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-xxl-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-xxl-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-xxl-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-xxl-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-xxl-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-xxl-0 {
    padding-right: 0 !important; }
  .pr-xxl-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-xxl-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-xxl-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-xxl-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-xxl-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-xxl-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-xxl-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-xxl-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-xxl-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-xxl-0 {
    padding-left: 0 !important; }
  .pl-xxl-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-xxl-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-xxl-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-xxl-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-xxl-xl {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .text-xxl-justify {
    text-align: justify !important; }
  .w-xxl-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-xxl-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-xxl-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-xxl-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-xxl-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-xxl-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-xxl-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-xxl-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-xxl-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-xxl-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-xxl-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-xxl-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-xxl-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-xxl-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-xxl-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-xxl-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-xxl-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-xxl-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-xxl-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-xxl-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-xxl-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-xxl-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-xxl-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-xxl-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .mw-xxl-100 {
    max-width: 100% !important; }
  .vw-xxl-100 {
    width: 100vw !important; }
  .min-xxl-vw-100 {
    min-width: 100vw !important; }
  .h-xxl-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-xxl-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-xxl-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-xxl-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-xxl-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-xxl-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-xxl-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-xxl-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-xxl-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-xxl-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-xxl-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-xxl-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-xxl-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-xxl-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-xxl-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-xxl-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-xxl-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-xxl-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-xxl-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-xxl-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-xxl-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-xxl-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-xxl-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-xxl-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-xxl-25 {
    height: 25% !important; }
  .h-xxl-50 {
    height: 50% !important; }
  .h-xxl-75 {
    height: 75% !important; }
  .h-xxl-100 {
    height: 100% !important; }
  .h-xxl-auto {
    height: auto !important; }
  .mh-xxl-100 {
    max-height: 100% !important; }
  .vh-xxl-100 {
    height: 100vh !important; }
  .min-xxl-vh-100 {
    min-height: 100vh !important; } }

@media screen and (min-width: 2600px) {
  .float-xxxl-left {
    float: left !important; }
  .float-xxxl-right {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; }
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: inline-flex !important; }
  .flex-xxxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxxl-row {
    flex-direction: row !important; }
  .flex-xxxl-column {
    flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    justify-content: center !important; }
  .justify-content-xxxl-between {
    justify-content: space-between !important; }
  .justify-content-xxxl-around {
    justify-content: space-around !important; }
  .align-items-xxxl-start {
    align-items: flex-start !important; }
  .align-items-xxxl-end {
    align-items: flex-end !important; }
  .align-items-xxxl-center {
    align-items: center !important; }
  .align-items-xxxl-baseline {
    align-items: baseline !important; }
  .align-items-xxxl-stretch {
    align-items: stretch !important; }
  .align-content-xxxl-start {
    align-content: flex-start !important; }
  .align-content-xxxl-end {
    align-content: flex-end !important; }
  .align-content-xxxl-center {
    align-content: center !important; }
  .align-content-xxxl-between {
    align-content: space-between !important; }
  .align-content-xxxl-around {
    align-content: space-around !important; }
  .align-content-xxxl-stretch {
    align-content: stretch !important; }
  .align-self-xxxl-auto {
    align-self: auto !important; }
  .align-self-xxxl-start {
    align-self: flex-start !important; }
  .align-self-xxxl-end {
    align-self: flex-end !important; }
  .align-self-xxxl-center {
    align-self: center !important; }
  .align-self-xxxl-baseline {
    align-self: baseline !important; }
  .align-self-xxxl-stretch {
    align-self: stretch !important; }
  .order-xxxl-first {
    order: -1 !important; }
  .order-xxxl-0 {
    order: 0 !important; }
  .order-xxxl-1 {
    order: 1 !important; }
  .order-xxxl-2 {
    order: 2 !important; }
  .order-xxxl-3 {
    order: 3 !important; }
  .order-xxxl-4 {
    order: 4 !important; }
  .order-xxxl-5 {
    order: 5 !important; }
  .order-xxxl-6 {
    order: 6 !important; }
  .order-xxxl-7 {
    order: 7 !important; }
  .order-xxxl-8 {
    order: 8 !important; }
  .order-xxxl-9 {
    order: 9 !important; }
  .order-xxxl-10 {
    order: 10 !important; }
  .order-xxxl-11 {
    order: 11 !important; }
  .order-xxxl-12 {
    order: 12 !important; }
  .order-xxxl-last {
    order: 13 !important; }
  .m-xxxl-0 {
    margin: 0 !important; }
  .m-xxxl-xs {
    margin: var(--epods--space-inset-xs) !important; }
  .m-xxxl-s {
    margin: var(--epods--space-inset-s) !important; }
  .m-xxxl-m {
    margin: var(--epods--space-inset-m) !important; }
  .m-xxxl-l {
    margin: var(--epods--space-inset-l) !important; }
  .m-xxxl-xl {
    margin: var(--epods--space-inset-xl) !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxxl-xs {
    margin-right: var(--epods--space-inset-xs) !important;
    margin-left: var(--epods--space-inset-xs) !important; }
  .mx-xxxls2 {
    margin-right: var(--epods--space-inset-s) !important;
    margin-left: var(--epods--space-inset-s) !important; }
  .mx-xxxl-m {
    margin-right: var(--epods--space-inset-m) !important;
    margin-left: var(--epods--space-inset-m) !important; }
  .mx-xxxl-l {
    margin-right: var(--epods--space-inset-l) !important;
    margin-left: var(--epods--space-inset-l) !important; }
  .mx-xxxl-xl {
    margin-right: var(--epods--space-inset-xl) !important;
    margin-left: var(--epods--space-inset-xl) !important; }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxxl-xs {
    margin-top: var(--epods--space-inset-xs) !important;
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .my-xxxls2 {
    margin-top: var(--epods--space-inset-s) !important;
    margin-bottom: var(--epods--space-inset-s) !important; }
  .my-xxxl-m {
    margin-top: var(--epods--space-inset-m) !important;
    margin-bottom: var(--epods--space-inset-m) !important; }
  .my-xxxl-l {
    margin-top: var(--epods--space-inset-l) !important;
    margin-bottom: var(--epods--space-inset-l) !important; }
  .my-xxxl-xl {
    margin-top: var(--epods--space-inset-xl) !important;
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxxl-0 {
    margin-top: 0 !important; }
  .mt-xxxl-xs {
    margin-top: var(--epods--space-inset-xs) !important; }
  .mt-xxxls2 {
    margin-top: var(--epods--space-inset-s) !important; }
  .mt-xxxl-m {
    margin-top: var(--epods--space-inset-m) !important; }
  .mt-xxxl-l {
    margin-top: var(--epods--space-inset-l) !important; }
  .mt-xxxl-xl {
    margin-top: var(--epods--space-inset-xl) !important; }
  .mt-xxxl-auto {
    margin-top: auto !important; }
  .mr-xxxl-0 {
    margin-right: 0 !important; }
  .mr-xxxl-xs {
    margin-right: var(--epods--space-inset-xs) !important; }
  .mr-xxxls2 {
    margin-right: var(--epods--space-inset-s) !important; }
  .mr-xxxl-m {
    margin-right: var(--epods--space-inset-m) !important; }
  .mr-xxxl-l {
    margin-right: var(--epods--space-inset-l) !important; }
  .mr-xxxl-xl {
    margin-right: var(--epods--space-inset-xl) !important; }
  .mr-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxxl-xs {
    margin-bottom: var(--epods--space-inset-xs) !important; }
  .mb-xxxls2 {
    margin-bottom: var(--epods--space-inset-s) !important; }
  .mb-xxxl-m {
    margin-bottom: var(--epods--space-inset-m) !important; }
  .mb-xxxl-l {
    margin-bottom: var(--epods--space-inset-l) !important; }
  .mb-xxxl-xl {
    margin-bottom: var(--epods--space-inset-xl) !important; }
  .mb-xxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxl-0 {
    margin-left: 0 !important; }
  .ml-xxxl-xs {
    margin-left: var(--epods--space-inset-xs) !important; }
  .ml-xxxls2 {
    margin-left: var(--epods--space-inset-s) !important; }
  .ml-xxxl-m {
    margin-left: var(--epods--space-inset-m) !important; }
  .ml-xxxl-l {
    margin-left: var(--epods--space-inset-l) !important; }
  .ml-xxxl-xl {
    margin-left: var(--epods--space-inset-xl) !important; }
  .ml-xxxl-auto {
    margin-left: auto !important; }
  .m-xxxl-nxs {
    margin: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .m-xxxl-ns {
    margin: calc(-1 * var(--epods--space-inset-s)) !important; }
  .m-xxxl-nm {
    margin: calc(-1 * var(--epods--space-inset-m)) !important; }
  .m-xxxl-nl {
    margin: calc(-1 * var(--epods--space-inset-l)) !important; }
  .m-xxxl-nxl {
    margin: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mx-xxxl-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mx-xxxl-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mx-xxxl-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mx-xxxl-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mx-xxxl-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .my-xxxl-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .my-xxxl-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .my-xxxl-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .my-xxxl-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .my-xxxl-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important;
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mt-xxxl-nxs {
    margin-top: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mt-xxxl-ns {
    margin-top: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mt-xxxl-nm {
    margin-top: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mt-xxxl-nl {
    margin-top: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mt-xxxl-nxl {
    margin-top: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mr-xxxl-nxs {
    margin-right: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mr-xxxl-ns {
    margin-right: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mr-xxxl-nm {
    margin-right: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mr-xxxl-nl {
    margin-right: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mr-xxxl-nxl {
    margin-right: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .mb-xxxl-nxs {
    margin-bottom: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .mb-xxxl-ns {
    margin-bottom: calc(-1 * var(--epods--space-inset-s)) !important; }
  .mb-xxxl-nm {
    margin-bottom: calc(-1 * var(--epods--space-inset-m)) !important; }
  .mb-xxxl-nl {
    margin-bottom: calc(-1 * var(--epods--space-inset-l)) !important; }
  .mb-xxxl-nxl {
    margin-bottom: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .ml-xxxl-nxs {
    margin-left: calc(-1 * var(--epods--space-inset-xs)) !important; }
  .ml-xxxl-ns {
    margin-left: calc(-1 * var(--epods--space-inset-s)) !important; }
  .ml-xxxl-nm {
    margin-left: calc(-1 * var(--epods--space-inset-m)) !important; }
  .ml-xxxl-nl {
    margin-left: calc(-1 * var(--epods--space-inset-l)) !important; }
  .ml-xxxl-nxl {
    margin-left: calc(-1 * var(--epods--space-inset-xl)) !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .p-xxxl-xs {
    padding: var(--epods--space-inset-xs) !important; }
  .p-xxxl-s {
    padding: var(--epods--space-inset-s) !important; }
  .p-xxxl-m {
    padding: var(--epods--space-inset-m) !important; }
  .p-xxxl-l {
    padding: var(--epods--space-inset-l) !important; }
  .p-xxxl-xl {
    padding: var(--epods--space-inset-xl) !important; }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxxl-xs {
    padding-right: var(--epods--space-inset-xs) !important;
    padding-left: var(--epods--space-inset-xs) !important; }
  .px-xxxl-s {
    padding-right: var(--epods--space-inset-s) !important;
    padding-left: var(--epods--space-inset-s) !important; }
  .px-xxxl-m {
    padding-right: var(--epods--space-inset-m) !important;
    padding-left: var(--epods--space-inset-m) !important; }
  .px-xxxl-l {
    padding-right: var(--epods--space-inset-l) !important;
    padding-left: var(--epods--space-inset-l) !important; }
  .px-xxxl-xl {
    padding-right: var(--epods--space-inset-xl) !important;
    padding-left: var(--epods--space-inset-xl) !important; }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxxl-xs {
    padding-top: var(--epods--space-inset-xs) !important;
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .py-xxxl-s {
    padding-top: var(--epods--space-inset-s) !important;
    padding-bottom: var(--epods--space-inset-s) !important; }
  .py-xxxl-m {
    padding-top: var(--epods--space-inset-m) !important;
    padding-bottom: var(--epods--space-inset-m) !important; }
  .py-xxxl-l {
    padding-top: var(--epods--space-inset-l) !important;
    padding-bottom: var(--epods--space-inset-l) !important; }
  .py-xxxl-xl {
    padding-top: var(--epods--space-inset-xl) !important;
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pt-xxxl-0 {
    padding-top: 0 !important; }
  .pt-xxxl-xs {
    padding-top: var(--epods--space-inset-xs) !important; }
  .pt-xxxl-s {
    padding-top: var(--epods--space-inset-s) !important; }
  .pt-xxxl-m {
    padding-top: var(--epods--space-inset-m) !important; }
  .pt-xxxl-l {
    padding-top: var(--epods--space-inset-l) !important; }
  .pt-xxxl-xl {
    padding-top: var(--epods--space-inset-xl) !important; }
  .pr-xxxl-0 {
    padding-right: 0 !important; }
  .pr-xxxl-xs {
    padding-right: var(--epods--space-inset-xs) !important; }
  .pr-xxxl-s {
    padding-right: var(--epods--space-inset-s) !important; }
  .pr-xxxl-m {
    padding-right: var(--epods--space-inset-m) !important; }
  .pr-xxxl-l {
    padding-right: var(--epods--space-inset-l) !important; }
  .pr-xxxl-xl {
    padding-right: var(--epods--space-inset-xl) !important; }
  .pb-xxxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxxl-xs {
    padding-bottom: var(--epods--space-inset-xs) !important; }
  .pb-xxxl-s {
    padding-bottom: var(--epods--space-inset-s) !important; }
  .pb-xxxl-m {
    padding-bottom: var(--epods--space-inset-m) !important; }
  .pb-xxxl-l {
    padding-bottom: var(--epods--space-inset-l) !important; }
  .pb-xxxl-xl {
    padding-bottom: var(--epods--space-inset-xl) !important; }
  .pl-xxxl-0 {
    padding-left: 0 !important; }
  .pl-xxxl-xs {
    padding-left: var(--epods--space-inset-xs) !important; }
  .pl-xxxl-s {
    padding-left: var(--epods--space-inset-s) !important; }
  .pl-xxxl-m {
    padding-left: var(--epods--space-inset-m) !important; }
  .pl-xxxl-l {
    padding-left: var(--epods--space-inset-l) !important; }
  .pl-xxxl-xl {
    padding-left: var(--epods--space-inset-xl) !important; }
  .text-xxxl-left {
    text-align: left !important; }
  .text-xxxl-right {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; }
  .text-xxxl-justify {
    text-align: justify !important; }
  .w-xxxl-1-24 {
    width: calc((100% / 24) * 1) !important; }
  .w-xxxl-2-24 {
    width: calc((100% / 24) * 2) !important; }
  .w-xxxl-3-24 {
    width: calc((100% / 24) * 3) !important; }
  .w-xxxl-4-24 {
    width: calc((100% / 24) * 4) !important; }
  .w-xxxl-5-24 {
    width: calc((100% / 24) * 5) !important; }
  .w-xxxl-6-24 {
    width: calc((100% / 24) * 6) !important; }
  .w-xxxl-7-24 {
    width: calc((100% / 24) * 7) !important; }
  .w-xxxl-8-24 {
    width: calc((100% / 24) * 8) !important; }
  .w-xxxl-9-24 {
    width: calc((100% / 24) * 9) !important; }
  .w-xxxl-10-24 {
    width: calc((100% / 24) * 10) !important; }
  .w-xxxl-11-24 {
    width: calc((100% / 24) * 11) !important; }
  .w-xxxl-12-24 {
    width: calc((100% / 24) * 12) !important; }
  .w-xxxl-13-24 {
    width: calc((100% / 24) * 13) !important; }
  .w-xxxl-14-24 {
    width: calc((100% / 24) * 14) !important; }
  .w-xxxl-15-24 {
    width: calc((100% / 24) * 15) !important; }
  .w-xxxl-16-24 {
    width: calc((100% / 24) * 16) !important; }
  .w-xxxl-17-24 {
    width: calc((100% / 24) * 17) !important; }
  .w-xxxl-18-24 {
    width: calc((100% / 24) * 18) !important; }
  .w-xxxl-19-24 {
    width: calc((100% / 24) * 19) !important; }
  .w-xxxl-20-24 {
    width: calc((100% / 24) * 20) !important; }
  .w-xxxl-21-24 {
    width: calc((100% / 24) * 21) !important; }
  .w-xxxl-22-24 {
    width: calc((100% / 24) * 22) !important; }
  .w-xxxl-23-24 {
    width: calc((100% / 24) * 23) !important; }
  .w-xxxl-24-24 {
    width: calc((100% / 24) * 24) !important; }
  .w-xxxl-25 {
    width: 25% !important; }
  .w-xxxl-50 {
    width: 50% !important; }
  .w-xxxl-75 {
    width: 75% !important; }
  .w-xxxl-100 {
    width: 100% !important; }
  .w-xxxl-auto {
    width: auto !important; }
  .mw-xxxl-100 {
    max-width: 100% !important; }
  .vw-xxxl-100 {
    width: 100vw !important; }
  .min-xxxl-vw-100 {
    min-width: 100vw !important; }
  .h-xxxl-1-24 {
    height: calc((100% / 24) * 1) !important; }
  .h-xxxl-2-24 {
    height: calc((100% / 24) * 2) !important; }
  .h-xxxl-3-24 {
    height: calc((100% / 24) * 3) !important; }
  .h-xxxl-4-24 {
    height: calc((100% / 24) * 4) !important; }
  .h-xxxl-5-24 {
    height: calc((100% / 24) * 5) !important; }
  .h-xxxl-6-24 {
    height: calc((100% / 24) * 6) !important; }
  .h-xxxl-7-24 {
    height: calc((100% / 24) * 7) !important; }
  .h-xxxl-8-24 {
    height: calc((100% / 24) * 8) !important; }
  .h-xxxl-9-24 {
    height: calc((100% / 24) * 9) !important; }
  .h-xxxl-10-24 {
    height: calc((100% / 24) * 10) !important; }
  .h-xxxl-11-24 {
    height: calc((100% / 24) * 11) !important; }
  .h-xxxl-12-24 {
    height: calc((100% / 24) * 12) !important; }
  .h-xxxl-13-24 {
    height: calc((100% / 24) * 13) !important; }
  .h-xxxl-14-24 {
    height: calc((100% / 24) * 14) !important; }
  .h-xxxl-15-24 {
    height: calc((100% / 24) * 15) !important; }
  .h-xxxl-16-24 {
    height: calc((100% / 24) * 16) !important; }
  .h-xxxl-17-24 {
    height: calc((100% / 24) * 17) !important; }
  .h-xxxl-18-24 {
    height: calc((100% / 24) * 18) !important; }
  .h-xxxl-19-24 {
    height: calc((100% / 24) * 19) !important; }
  .h-xxxl-20-24 {
    height: calc((100% / 24) * 20) !important; }
  .h-xxxl-21-24 {
    height: calc((100% / 24) * 21) !important; }
  .h-xxxl-22-24 {
    height: calc((100% / 24) * 22) !important; }
  .h-xxxl-23-24 {
    height: calc((100% / 24) * 23) !important; }
  .h-xxxl-24-24 {
    height: calc((100% / 24) * 24) !important; }
  .h-xxxl-25 {
    height: 25% !important; }
  .h-xxxl-50 {
    height: 50% !important; }
  .h-xxxl-75 {
    height: 75% !important; }
  .h-xxxl-100 {
    height: 100% !important; }
  .h-xxxl-auto {
    height: auto !important; }
  .mh-xxxl-100 {
    max-height: 100% !important; }
  .vh-xxxl-100 {
    height: 100vh !important; }
  .min-xxxl-vh-100 {
    min-height: 100vh !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/****************************/
/*         SCROLL BAR        */
/****************************/
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--epods--color-bg-scroll) transparent; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px; }

*::-webkit-scrollbar-track {
  background: transparent; }

*::-webkit-scrollbar-thumb {
  box-shadow: 0 0 10px 10px var(--epods--color-bg-scroll) inset;
  border-radius: 8px;
  border: 4px solid transparent; }

/******************************/
/*         TOOLTIP             */
/*******************************/
/* use for any tag on your page */
[data-title]:focus:after {
  opacity: 1;
  transition: all 0.2s ease var(--epods--title-tooltip-focusdalay);
  visibility: visible;
  top: var(--epods--title-tooltip-top);
  bottom: var(--epods--title-tooltip-bottom);
  left: var(--epods--title-tooltip-left);
  right: var(--epods--title-tooltip-right); }

/* use for any tag on your page */
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.2s ease 0.5s;
  visibility: visible;
  top: var(--epods--title-tooltip-top);
  bottom: var(--epods--title-tooltip-bottom);
  left: var(--epods--title-tooltip-left);
  right: var(--epods--title-tooltip-right); }

/* box for title text */
[data-title]:after {
  content: attr(data-title);
  /* position for title view box */
  position: absolute;
  z-index: 99999;
  visibility: hidden;
  /* optional */
  white-space: nowrap;
  /* stilization */
  background-color: var(--epods--color-bg-overlays);
  color: var(--epods--color-text-inverted);
  font-family: var(--epods--font-base);
  font-size: var(--epods--font-size-p);
  line-height: var(--epods--line-height-p);
  padding: var(--epods--space-inset-s) var(--epods--space-inset-s);
  opacity: 0; }

[data-title] {
  position: relative;
  --epods--title-tooltip-top: calc(100% + var(--epods--space-inset-m));
  --epods--title-tooltip-focusdalay: 2s; }

[data-title]:hover,
[data-title]:focus {
  overflow: visible; }
